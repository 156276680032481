import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';

const BlogPostTemplate = ({ data }) => {
  const { mdx: post } = data;

  return (
    <DefaultLayout
      metaDescription={post.frontmatter.description}
      title={post.frontmatter.title}
      url={post.frontmatter.path}
      lang={post.frontmatter.path.startsWith('/en/') ? 'en' : 'pl'}
    >
      <Hero
        date={post.frontmatter.date}
        image={
          post.frontmatter.featuredImage &&
          post.frontmatter.featuredImage.childImageSharp.fluid
        }
        title={post.frontmatter.title}
        withBorder={!post.frontmatter.featuredImage}
      />
      <main>
        <article className="article">
          <MDXRenderer>{post.body}</MDXRenderer>
        </article>
      </main>
    </DefaultLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
